<template>
  <div>
    <template v-for="cat in categories">
      <v-chip
        :key="cat.id"
        small label
        :color="cat.color"
        style="font-size: 14px;"
        :class="'mt-1 mr-1 py-1 ' + `${$utils.textColorForBackground(cat.color)}--text`"
      >
        {{ cat.name }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: "DocCategoryChips",
  props: {
    categories: {required: true},
  },
}
</script>
