<template>
  <v-dialog v-model="dialog" width="760px" :persistent="editing.active">
    <v-card :loading="loading" style="overflow: hidden">
      <v-toolbar
        :color="
          editing.active ? 'primary white--text' : (
            document.archived ? 'warning white--text'
            :'grey lighten-2'
          )
        "
        min-height="60px" :elevation="0"
      >
        <v-toolbar-title>
          {{ document.name }}
        </v-toolbar-title>
        <v-fade-transition>
          <v-chip
            v-if="document.archived"
            small label class="ml-4"
            color="grey darken-3 white--text"
          >
            arquivado
          </v-chip>
        </v-fade-transition>
        <v-spacer />
        <v-fade-transition>
          <v-btn
            v-if="!editing.active && !document.archived"
            @click="startEditing()"
            small depressed class="mr-2"
            color="grey darken-1 white--text"
          >
            editar
          </v-btn>
        </v-fade-transition>
        <v-fade-transition>
          <v-toolbar-items v-if="!editing.active">
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-fade-transition>
      </v-toolbar>
      <v-form ref="form" class="pa-2" @submit.prevent="saveDocument()">

        <div class="d-flex mb-1">
          <div class="flex-grow-1">
            <DataRow
              :label="'Nome' + (editing.active ? ' *':'')"
              :rules="[$rules.required]"
              v-model="editing.document.name"
              :editing="editing.active"
              :disabled="loadingSave"
            >
              {{ document.name }}
            </DataRow>
          </div>
          <DataRow
            label="Data de envio"
            class="ml-1"
            width="200px"
            :value="$utils.formatDateTime(document.upload_date)"
          />
        </div>

        <DataRow
          label="Descrição"
          class="mb-1"
          v-model="editing.document.description"
          :editing="editing.active"
          :disabled="loadingSave"
        >
          {{ document.description || "Não Informada" }}
        </DataRow>

        <DataRow label="Destino" class="mb-1" :editing="editing.active">
          {{ (document.client || {}).name || "Arquivo do Escritório" }}
          <template #field>
            <CompanyField
              label="Destino *"
              v-model="editing.document.client_id"
              :rules="[$rules.required]"
              allowWithoutClient
              :disabled="loadingSave"
            />
          </template>
        </DataRow>

        <DataRow label="Categorias" class="mb-1" :editing="editing.active">
          <DocCategoryChips :categories="document.categories" />
          <template #field>
            <DocCategorySelect
              label="Categorias *"
              v-model="editing.document.categories"
              multiple
              :rules="[$rules.requiredList]"
              :disabled="loadingSave"
            />
          </template>
        </DataRow>

        <v-fade-transition leave-absolute>
          <div v-if="editing.active" class="mt-2 d-flex justify-end">
            <v-btn
              text class="mr-2"
              :disabled="loadingSave"
              @click="editing.active = false"
            >
              cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="loadingSave">
              salvar
            </v-btn>
          </div>

          <div v-else>
            <v-divider class="my-3" />

            <div class="d-flex align-center">
              <v-btn
                text class="px-1"
                :color="document.archived ? 'success darken-1':'warning darken-2'"
                @click="document.archived ? restore():archive()"
              >
                {{ document.archived ? "restaurar":"arquivar" }}
                <v-icon right>mdi-archive</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                color="success"
                target="_blank" :href="$axios.defaults.baseURL + `/document/${document.id}/file`"
              >
                Abrir
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </div>
        </v-fade-transition>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DataRow from '@/components/core/DataRow.vue';
import CompanyField from "@/components/CompanyField.vue";
import DocCategorySelect from '@/components/documents/DocCategorySelect.vue';
import DocCategoryChips from '@/components/documents/DocCategoryChips.vue';

export default {
  name: "ViewDocument",

  components: {
    DataRow,
    CompanyField,
    DocCategorySelect,
    DocCategoryChips,
  },

  data: () => ({
    dialog: false,
    loading: false,
    loadingSave: false,
    document: {},
    editing: {
      active: false,
      document: {},
    },
  }),

  methods: {
    open(document) {
      this.document = document;
      this.dialog = true;
      this.loadDocumentData();
    },
    loadDocumentData(){
      this.$axios.get(`/document/${this.document.id}`)
        .then((response) => {
          this.document = response.data;
        })
        .catch(() => {
          this.$showMessage('error', "Erro ao carregar mais dados do documento");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startEditing(){
      this.$set(this.editing, 'document', {
        name: `${this.document.name}`,
        description: this.document.description,
        client_id: this.document.client_id || -1,
        categories: this.document.categories.map(c => c.id),
      });
      this.editing.active = true;
    },
    saveDocument(){
      if (this.$refs.form.validate()){
        this.loadingSave = true;
        const client_id = this.editing.document.client_id;
        this.$axios.patch(`/document/${this.document.id}`, {
          ...this.editing.document,
          client_id: (client_id == -1) ? undefined : client_id,
        })
          .then(() => {
            this.$showMessage('success', "Documento salvo");
            this.editing.active = false;
            this.loadDocumentData();
            this.$emit('updated');
          })
          .catch(() => {
            this.$showMessage('error', "Erro inesperado ao salvar documento");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    showFormatedDate(item) {
      if (item) {
        var my = item.split("-");
        var dd = my[2].split("T");
        return dd[0] + "/" + my[1] + "/" + my[0];
      } else return 0;
    },
    documentSize(size) {
      if (size == undefined) return "";
      const sizes = ['GB', 'MB', 'KB', 'B'];
      let sizeIndex = 0;
      while (size < 1 && sizeIndex < sizes.length-1){
        size *= 1024;
        sizeIndex++;
      }
      return size.toFixed(2).replace('.',',') +" "+ sizes[sizeIndex];
    },
    archive(){
      this.$confirmDialog(
        `Você deseja arquivar ${this.document.name}?`,
        'O arquivo poderá ser restaurado posteriormente.',
        () => new Promise((resolve, reject) => {
          this.$axios.delete(`/document/${this.document.id}`)
          .then(() => {
            resolve();
            this.$showMessage('success', "Documento Arquivado");
            this.loadDocumentData();
            this.$emit('updated');
          })
          .catch((error) => {
            reject();
            this.$showMessage("error", "Erro inesperado ao arquivar documento");
          })
        }),
      )
    },
    restore(){
      this.$axios.patch(`/document/${this.document.id}/restore`)
      .then(() => {
        this.$showMessage('success', "Documento Restaurado");
        this.loadDocumentData();
        this.$emit('updated');
      })
      .catch((error) => {
        this.$showMessage("error", "Erro inesperado ao restaurar documento");
      });
    },
  },
};
</script>
