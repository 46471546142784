<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-toolbar flat dark color="primary" max-height="65px">
      <v-toolbar-title>
        Links para os formulários
      </v-toolbar-title>
      <v-spacer />
      <v-btn @click="dialog = false" color="red">
        Fechar
      </v-btn>
    </v-toolbar>
    <v-spacer />
    <v-data-table
      :items="clients"
      :headers="headers"
      hide-default-footer
      fixed-header
    >
      <template v-slot:[`item.code`]="{ item }">
        <v-chip
          label
          small
          class="justify-center"
        >
          <a :href="responseLink(item.code)" target="_blank">
            {{ responseLink(item.code) }}
          </a>
        </v-chip>
      </template>
    </v-data-table>
  </v-dialog>
</template>

<script>

export default {
  name: "FormLink",
  components: {
  },

  data: () => ({
    clients: [],
    dialog: false,

    headers: [
      {
        text: "Cliente",
        value: "client.name",
        filtrable: false,
        sortable: false,
      },
      {
        text: "Link",
        value: "code",
        filtrable: false,
        sortable: false,
      },
    ],
  }),

  methods: {
    responseLink(code){
      return window.location.protocol + '//'
           + window.location.host
           + '/formulario/' + code;
    },

    open(response) {
      this.clients = response;
      this.dialog = true;
    },

    closer() {
      this.dialog = false;
      this.$emit("close");
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) this.links = [];
    },
  },
};
</script>
