<template>
  <v-dialog v-model="categoriesDialog" max-width="600px">
    <v-card>
      <ChangeCategoryDialog
        ref="changeCategoryDialog"
        @saved="$emit('updated'); loadCategories()"
      />
      <v-toolbar flat color="grey lighten-2" max-height="65px">
        <v-toolbar-title> Categorias dos Documentos </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items class="align-center">
          <div>
            <v-btn small class="mr-3" color="primary" @click="newCategory()">
              <v-icon small class="pr-1">mdi-plus</v-icon>
              Nova Categoria
            </v-btn>
          </div>
          <v-btn icon @click="categoriesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        loading-text="Carregando... Por favor, aguarde"
        no-data-text="Nenhuma categoria cadastrada"
        no-results-text="Nenhuma categoria encontrada"
        :items="categories"
        :headers="headers"
        disable-pagination
        hide-default-footer
        height="500px"
        fixed-header
      >
        <template #[`item.color`]="{ item }">
          <v-chip
            @click="editCategory(item)"
            label
            :style="{ backgroundColor: item.color }"
            style="min-width: 35px"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                class="ml-2"
                icon
                v-on="on"
                @click="editCategory(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar Categoria</span>
          </v-tooltip>
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                color="error"
                class="ml-2"
                v-on="on"
                @click="deleteCategory(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Deletar Categoria</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import ChangeCategoryDialog from "@/components/form_requests/ChangeCategoryDialog.vue";

export default {
  components: {
    ChangeCategoryDialog,
  },
  name: "CategoriesDialog",
  data: () => ({
    loading: false,
    deleteIndex: 0,
    categoriesDialog: false,
    categories: [{ name: "Categoria 1", color: "yellow" }],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Cor", value: "color" },
      { text: "", value: "actions", sortable: false, align: "end" },
    ],
  }),
  methods: {
    newCategory() {
      this.$refs.changeCategoryDialog.open();
    },
    editCategory(item) {
      this.$refs.changeCategoryDialog.open(item);
    },
    deleteCategory(category) {
      this.$confirmDialog(
        `Deseja deletar a categoria ${category.name}?`,
        "A categoria será deletada permanentemente.",
        () => new Promise((resolve, reject) => {
          this.$axios
            .delete("/doc/category/" + category.id)
            .then(() => {
              resolve();
              this.$showMessage("success", "Categoria Deletada");
              this.loadCategories();
              this.$emit('updated');
            })
            .catch((error) => {
              reject();
              this.$showMessage("error", "Erro inesperado ao deletar categoria");
            });
        }),
      );
    },
    open() {
      this.categoriesDialog = true;
      this.loadCategories();
    },
    async loadCategories() {
      this.loading = true;
      this.$axios
        .get("/doc/categories")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          this.$showMenssage(
            "error",
            "Erro inesperado ao Carregar as Categorias"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
