<template>
  <v-dialog v-model="dialog" max-width="650px" :persistent="!readonly">
    <v-card>
      <v-toolbar flat dark color="primary" max-height="65px">
        <v-toolbar-title>
          {{ newCompany ? "Novo Cliente " : (readonly ? "Cliente" : "Edição de Cliente")}}
        </v-toolbar-title>
        <v-spacer />
        <v-fade-transition>
          <v-toolbar-items v-if="readonly && !company.is_accountant" class="align-center">
            <div>
              <v-btn
                small depressed
                class="mr-4"
                color="error darken-1"
                @click="deleteCompany()"
              >
                <v-icon small class="mr-1">mdi-delete</v-icon>
                Deletar
              </v-btn>
              <v-btn
                small depressed
                color="grey lighten-3 black--text"
                class="mr-4"
                @click="readonly = false"
              >
                <v-icon small class="pr-2">mdi-pencil</v-icon>
                Editar Cliente
              </v-btn>
            </div>
            <v-btn icon class="mr-1" @click="dialog = false">
              <v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-fade-transition>
      </v-toolbar>
      <v-form ref="form" @submit.prevent="saveCompany()">
        <v-card-text>
          <v-select
            v-if="!company.is_accountant"
            :disabled="loading"
            label="Contador *"
            class="mb-4"
            filled hide-details="auto"
            :readonly="readonly"
            v-model="company.accountant_id"
            :items="accountantCompanies"
            item-text="name"
            item-value="id"
            :rules="[$rules.required]"
          />
          <v-radio-group v-model="clientType" dense row :readonly="readonly">
            <v-radio value="cnpj" label="Pessoa Jurídica"/>
            <v-radio value="cpf" label="Pessoa Física"/>
          </v-radio-group>
          <v-text-field
            :disabled="loading"
            :label="clientType == 'cnpj' ? 'Razão Social *' : 'Nome Completo *'"
            class="mb-4"
            filled hide-details="auto"
            :readonly="readonly"
            v-model="company.name"
            :rules="[$rules.required]"
          />
          <template v-if="clientType == 'cnpj'">
            <v-text-field
              :disabled="loading"
              label="CNPJ *"
              class="mb-4"
              filled hide-details="auto"
              :readonly="readonly"
              v-model="company.cnpj"
              v-mask="['##.###.###/####-##']"
              :rules="[$rules.required, $rules.cnpj]"
            />
          </template>
          <template v-if="clientType == 'cpf'">
            <v-text-field
              :disabled="loading"
              label="CPF *"
              class="mb-4"
              filled hide-details="auto"
              :readonly="readonly"
              v-model="company.cpf"
              v-mask="['###.###.###-##']"
              :rules="[$rules.required, $rules.cpf]"
            />
          </template>
          <v-text-field
            :disabled="loading"
            label="E-Mail Principal *"
            class="mb-4"
            filled hide-details="auto"
            :readonly="readonly"
            v-model="company.email"
            :rules="[$rules.required]"
          />
        </v-card-text>
        <v-expand-transition>
          <v-card-actions class="pb-4 pr-4 pt-0" v-if="!readonly">
            <v-spacer />
            <v-btn
              text
              @click="dialog = false"
              :disabled="loading">
              Cancelar
            </v-btn>
            <v-btn
              class="success"
              type="submit"
              :loading="loading">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-expand-transition>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewEditAndCreateCompany",

  data: () => ({
    loading: false,
    loadingAccountants: false,
    clientType: 'cnpj',
    newCompany: false,
    dialog: false,
    readonly: true,
    accountantCompanies: [],
    company: {
      accountant_id: undefined,
      name: "",
      cnpj: "",
      cpf: "",
      email: "",
    },
  }),

  methods: {
    open(company) {
      this.loadAccountants();
      if (company !== undefined){
        this.newCompany = false;
        this.company = { ...company };
        this.readonly = true;
        this.clientType = (this.company.cnpj ? 'cnpj':'cpf');
      } else {
        this.company = {};
        this.newCompany = true;
        this.readonly = false;
        this.clientType = 'cnpj';
        if (this.$refs.form){
          this.$refs.form.reset();
        }
      }
      this.dialog = true;
    },
    loadAccountants(){
      this.loadingAccountants = true;
      this.$axios.get('/companies/available')
        .then((response) => {
          this.accountantCompanies = response.data.accountant;
          if (!this.company.accountant_id){
            this.company.accountant_id = this.accountantCompanies[0].id;
          }
        })
        .catch(() => {
          this.$showMessage('error', "Erro inesperado ao carregar clientes");
          this.dialog = false;
        })
        .finally(() => {
          this.loadingAccountants = false;
      })
    },
    saveCompany(){
      if (!this.$refs.form.validate()) {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      } else {
        if (this.clientType == 'cnpj'){
          this.company.cpf = null;
        } else {
          this.company.cnpj = null;
        }
        this.loading = true;
        let request;
        if (this.company.id){
          request = this.$axios.put(`/client/${this.company.id}`, this.company)
        } else {
          request = this.$axios.post('/client', this.company);
        }
        request.then((response) => {
          this.$emit('updated', response.data || {...this.company});
          if (this.company.id == undefined){
            this.$emit('created', response.data);
          }
          
          this.$showMessage("success", "Cliente Salvo");
          this.dialog = false;
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                this.$showMessage('warning', `${this.clientType.toUpperCase()} inválido`);
                return;
              case 409:
                this.$showMessage('warning', `${this.clientType.toUpperCase()} já em uso`);
                return;
              case 404:
                this.$showMessage('warning', "Cliente não encontrado");
                return;
            }
          }
          this.$showMessage("error", "Erro inesperado ao salvar o cliente");
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },

    deleteCompany() {
      this.$confirmDialog(
        "Você deseja deletar esse Cliente?",
        "O cliente será apagado permanentemente.",
        () =>
          new Promise((resolve, reject) => {
            this.$axios
              .delete(`/client/${this.company.id}`)
              .then(() => {
                resolve();
                this.$showMessage("success", "Cliente excluido");
                this.$emit('update');
                this.dialog = false;
              })
              .catch((error) => {
                reject();
                this.$showMessage("error", "Erro inesperado ao excluir");
              });
          })
      );
    },
  },
};
</script>
