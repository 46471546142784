<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title v-if="editing">Editar categoria</v-toolbar-title>
        <v-toolbar-title v-else>Nova categoria</v-toolbar-title>
      </v-toolbar>
      <v-form
        ref="form"
        @submit.prevent="saveChanges()"
      >
        <v-card-text class="d-flex align-start">
          <v-text-field
            class="mr-2"
            filled
            :rules="[$rules.required]"
            v-model="category.name"
            label="Nome *"
            hide-details="auto"
          />
          <color-picker v-model="category.color" label="Cor da Categoria" />
        </v-card-text>
        <v-card-actions class="pr-4 pb-4 pt-0">
          <v-spacer />
          <v-btn text @click="dialog = false" :disabled="loading">
            Cancelar
          </v-btn>
          <v-btn color="success" type="submit" :loading="loading">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorPicker from "@/components/ColorPicker.vue";
export default {
  name: "ChangeCategoryDialog",
  components: {
    ColorPicker,
  },

  data: () => ({
    editing: false,
    dialog: false,
    loading: false,
    category: {},
  }),

  methods: {
    open(category) {
      if (category){
        this.editing = true;
        this.category = { ...category };
      } else {
        this.editing = false;
        this.category = {
          name: "",
          color: "#05607C",
        };
        if (this.$refs.form){
          this.$refs.form.reset();
        }
      }
      this.dialog = true;
    },
    saveChanges() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let request;
        if (this.editing){
          request = this.$axios.put("/doc/category/" + this.category.id, this.category);
        } else {
          request = this.$axios.post("/doc/category", this.category);
        }
        request
          .then(() => {
            this.$showMessage("success", "Categoria Salva");
            this.$emit("saved");
            this.dialog = false;
          })
          .catch((error) => {
            this.$showMessage(
              "error",
              "Erro inesperado ao salvar a categoria"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      }
    },
  },
};
</script>
