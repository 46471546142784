<template>
  <v-dialog
    v-model="dialog"
    :max-width="recurrent ? '800px':'750px'"
  >
    <v-card :loading="loading">
      <v-toolbar flat color="grey lighten-2" height="65px">
        <v-toolbar-title>
          <div class="text-h6 flex-grow-1">
            {{ capitalize(form_request.title) || ''}}
          </div>
        </v-toolbar-title>
        <v-chip
          label
          :color="getStatusColor(form_request)"
          class="ml-3 font-weight-medium"
        >
          {{ getStatusText(form_request) }}
        </v-chip>
        <v-spacer />
        <v-btn
          v-if="form_request.status == 'pending'"
          small depressed class="mr-2"
          color="warning darken-1 white--text"
          @click="cancelRequest()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-if="recurrent"
          small depressed class="mr-2"
          :color="(form_request.status == 'active' ? 'warning':'success')+' darken-1'"
          @click="toggleActive()"
        >
          {{ form_request.status == 'active' ? "Desativar":"Ativar" }}
        </v-btn>
        <v-btn
          v-if="recurrent || form_request.status == 'pending'"
          small depressed class="mr-2"
          color="grey darken-1 white--text"
          @click="$refs.formCreatorDialog.open(recurrent ? 'recurrent':'requested', form_request.id)"
        >
          editar
        </v-btn>
        <v-toolbar-items>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-2" :style="{
        'max-height': ($vuetify.breakpoint.height*0.9 -65) + 'px',
        overflow: 'auto',
      }">

        <div class="mb-2 d-flex" v-if="form_request.status == 'pending'">
          <v-chip label color="grey darken-2 white--text" class="rounded-r-0">
            Link:
          </v-chip>
          <v-chip
            color="grey lighten-4"
            class="flex-grow-1 rounded-0"
            :href="responseLink"
            target="_blank"
          >
            {{ responseLink }}
          </v-chip>
          <v-chip
            label
            color="info"
            class="rounded-l-0 pl-4"
            @click="
              $utils.sendToClipboard(responseLink);
              $showMessage('info darken-1', 'Copiado');
            "
          >
            <v-icon left>mdi-content-copy</v-icon>
            copiar
          </v-chip>
        </div>
        
        <v-row no-gutters class="mb-1">
          <v-col>
            <DataRow label="Criado em" class="mr-1">
              {{ $utils.formatDateTime(form_request.creation_datetime) }}
            </DataRow>
          </v-col>
          <template v-if="recurrent">
            <v-col>
              <DataRow v-if="recurrent" label="Dia da Recorrência">
                {{ form_request.schedule_day }}
              </DataRow>
            </v-col>
            <v-col>
              <DataRow v-if="recurrent" label="Próxima Recorrência" class="ml-1">
                {{
                  form_request.next_scheduled ?
                    $utils.formatDate(form_request.next_scheduled)
                    : "Não definido"
                }}
              </DataRow>
            </v-col>
          </template>
          <v-col v-else>
            <DataRow label="Expira em" class="ml-1">
              {{
                form_request.expiration_datetime ?
                  $utils.formatDateTime(form_request.expiration_datetime)
                  : "Não definido"
              }}
            </DataRow>
          </v-col>
        </v-row>
        
        <DataRow v-if="form_request.description" label="Descrição" class="mb-1">
          {{ form_request.description }}
        </DataRow>
        
        <DataRow label="Cliente" class="mb-1">
          {{ form_request.client.name }}
        </DataRow>

        <DataRow label="Categorias" class="">
          <DocCategoryChips :categories="form_request.categories" />
        </DataRow>

        <div class="mt-2 mb-1 d-flex align-center">
          <v-divider class="grey darken-2" />
          <div class="mx-2 text-subtitle-1">
            {{ recurrent ? 'Requisições':'Respostas' }}
          </div>
          <v-divider class="grey darken-2" />
        </div>

        <template v-if="!recurrent">
          <template  v-for="question in form_request.questions">
            <DataRow
              :key="question.question_order"
              :label="question.title"
              class="my-1 text-body-2"
            >
              <template v-if="question.response_text">
                {{ question.response_text }}
              </template>

              <template v-else-if="question.response_monetary">
                R$ {{ $utils.formatMoney(question.response_monetary) }}
              </template>

              <template v-else-if="question.response_date">
                {{ $utils.formatDate(question.response_date) }}
              </template>

              <template v-else-if="['radio','checkbox'].includes(question.answer_type)">
                <v-chip
                  v-for="option in question.options"
                  :key="option.option_order"
                  :color="
                    option.is_selected
                    ? 'primary white--text'
                    : 'white grey--text text--darken-3'
                  "
                  small label
                  class="mr-1 mb-1"
                >
                  {{ option.value }}
                </v-chip>
              </template>

              <template v-else-if="question.answer_type == 'file'">
                <v-chip
                  v-for="document in question.documents"
                  :key="document.id"
                  label
                  class="mr-1 mb-1"
                  @click="$refs.viewDocument.open(document)"
                >
                  <div class="d-flex align-center">
                    <v-icon class="mr-2" size="18px">mdi-file-document</v-icon>
                    {{ document.name }}
                  </div>
                </v-chip>
              </template>

              <template v-else>
                Não respondida
              </template>
            </DataRow>
          </template>
        </template>

        <template v-for="request in form_request.form_requests">
          <v-card
            :key="request.id"
            outlined
            color="grey lighten-3"
            class="d-flex align-center mt-2 pa-2"
          >
            <div class="flex-grow-1">
              {{ request.title }}
            </div>
            <v-divider vertical class="mx-2" />
            <div class="text-subtitle-2 text-center" style="width: 130px">
              {{ $utils.formatDateTime(request.creation_datetime) }}
            </div>
            <v-divider vertical class="mx-2" />
            <v-chip
              label
              small
              :color="getStatusColor(request)"
              style="width: 120px"
              class="justify-center align-center font-weight-medium"
            >
              {{ getStatusText(request) }}
              <template v-if="request.status == 'pending'">
                {{ request.current_question + "/" + request.total_questions }}
              </template>
            </v-chip>
            <v-divider vertical class="mx-2" />
            <v-btn
              depressed color="info"
              @click="$refs.otherRequestDialog.open(request.id)"
            >
              Abrir
            </v-btn>
          </v-card>
        </template>

      </div>
    </v-card>
    <FormCreatorDialog
      ref="formCreatorDialog"
      @updated="
        loadFormRequest();
        $emit('updated');
      "
    />
    <ViewDocument ref="viewDocument" />
    <RequestDialog
      v-if="recurrent"
      ref="otherRequestDialog"
    />
  </v-dialog>
</template>

<script>
import DataRow from '@/components/core/DataRow.vue';
import FormCreatorDialog from "@/components/form_requests/FormCreatorDialog.vue";
import DocCategoryChips from '@/components/documents/DocCategoryChips.vue';
import ViewDocument from '@/components/documents/ViewDocument.vue';

export default {
  name: "RequestDialog",

  components: {
    FormCreatorDialog,
    DataRow,
    DocCategoryChips,
    ViewDocument,
  },

  props: {
    recurrent: {default: false, type: Boolean},
  },

  data: () => ({
    dialog: false,
    form_request_id: undefined,
    form_request: {
      client: {},
      questions: [],
      form_requests: [],
    },
    loading: false,
  }),

  computed: {
    responseLink(){
      return window.location.protocol + '//'
           + window.location.host
           + '/formulario/' + this.form_request.code;
    },
    location(){
      return location.host;
    },
  },
  methods: {
    getStatusColor(request) {
      switch (request.status){
        case null: return "yellow darken-2";
        case "expired":
        case "canceled":
        case "disabled":
          return "grey lighten-1";
        case "answered": return "green lighten-3";
        case "pending": return "warning";
        case "active": return 'success';
      }
      return 'grey';
    },
    getStatusText(request) {
      switch (request.status){
        case null: return "Modelo";
        case 'canceled': return "Cancelado";
        case 'answered': return "Respondido";
        case 'deleted': return "Excluído";
        case 'expired': return "Expirado";
        case 'pending': return "Pendente";
        // recurrent
        case 'disabled': return "Desativado";
        case 'active': return "Ativo";
      }
    },
    capitalize(text) {
      if (text) return text.charAt(0).toUpperCase() + text.slice(1);
    },
    cancelRequest() {
      this.$confirmDialog(
        "Deseja cancelar esse formulário?",
        "O cancelamento não poderá ser desfeito",
        () => new Promise((resolve, reject) => {
          this.$axios
            .patch("/form_request/" + this.form_request.id)
            .then(() => {
              resolve();
              this.$showMessage("success", "solicitação Cancelada");
              this.$emit("updated");
              this.dialog = false;
            })
            .catch((error) => {
              reject();
              console.log(error);
              this.$showMessage(
                "error",
                "Erro inesperado ao cancelar a solicitação"
              );
            });
        }),
      );
    },
    open(id){
      this.form_request_id = id;
      this.loadFormRequest();
      this.dialog = true;
    },
    async loadFormRequest() {
      let request = '/form_request';
      if (this.recurrent){
        request += '/recurrent';
      }
      try {
        this.loading = true;
        const response = await this.$axios.get(`${request}/${this.form_request_id}`);
        this.form_request = response.data;
      } catch (error){
        this.$showMessage("error", "Erro ao carregar o formulário");
      } finally {
        this.loading = false;
      }
    },
    async toggleActive(){
      this.loading = true;
      try {
        await this.$axios.patch(
          `/form_request/recurrent/${this.form_request_id}/set-status`,
          {
            status: (this.form_request.status == 'active') ? 'disabled':'active',
          }
        );
        this.$emit('updated');
        this.$showMessage('success', "Status da recorrência alterado");
      } catch (error) {
        this.$showMessage('error', "Erro ao alterar status da recorrência");
      } finally {
        this.loading = false;
        this.loadFormRequest();
      }
    },
  },
  watch: {
    dialog(isShowing, wasShowing){
      if (wasShowing && !isShowing){
        this.form_request = {
          client: {},
          questions: [],
        };
      }
    },
  },
};
</script>
