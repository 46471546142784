var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.categoriesDialog),callback:function ($$v) {_vm.categoriesDialog=$$v},expression:"categoriesDialog"}},[_c('v-card',[_c('ChangeCategoryDialog',{ref:"changeCategoryDialog",on:{"saved":function($event){_vm.$emit('updated'); _vm.loadCategories()}}}),_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2","max-height":"65px"}},[_c('v-toolbar-title',[_vm._v(" Categorias dos Documentos ")]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"align-center"},[_c('div',[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.newCategory()}}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Nova Categoria ")],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.categoriesDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-data-table',{attrs:{"loading-text":"Carregando... Por favor, aguarde","no-data-text":"Nenhuma categoria cadastrada","no-results-text":"Nenhuma categoria encontrada","items":_vm.categories,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","height":"500px","fixed-header":""},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"min-width":"35px"},style:({ backgroundColor: item.color }),attrs:{"label":""},on:{"click":function($event){return _vm.editCategory(item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.editCategory(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Categoria")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteCategory(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar Categoria")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }