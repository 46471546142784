<template>
  <div>
    <MultipleSelect
      :value="value"
      @input="$emit('input', $event)"
      item-text="name"
      item-value="id"
      :multiple="multiple"
      :loading="loadingClients"
      :disabled="disabled"
      :label="label"
      :rules="rules"
      no-data-text="Nenhum cliente encontrado"
      :items="clients"
      newLabel="Novo Cliente"
      @open-new="$refs.companyForm.open()"
    />
    <ViewEditAndCreateCompany
      ref="companyForm"
      @updated="
        loadClients();
        $emit('input', [...value, $event.id]);
      "
    />
  </div>
</template>

<script>
import MultipleSelect from "@/components/core/MultipleSelect.vue";
import ViewEditAndCreateCompany from "@/components/ViewEditAndCreateCompany.vue";

export default {
  name: "CompanyField",

  components: {
    MultipleSelect,
    ViewEditAndCreateCompany,
  },

  props: {
    value: {required: true},
    label: {default: "Clientes", type: String},
    multiple: {default: false, type: Boolean},
    disabled: {default: false, type: Boolean},
    rules: {default: undefined},
    additionalRows: {default: undefined, type: Array},
    allowWithoutClient: {default: false, type: Boolean},
  },

  data: () => ({
    loadingClients: false,
    clients: [],
  }),

  methods: {
    loadClients() {
      this.loadingClients = true;
      this.$axios
        .get("/clients")
        .then((response) => {
          this.clients = [
            ...(!this.allowWithoutClient ? []:[
              {
                id: -1,
                name: "Arquivo do Escritório",
              }
            ]),
            ...response.data,
          ];
        })
        .catch((error) => {
          this.$showMessage("error", "Não foi possivel carregar a lista de clientes");
          this.uploadDialog = false;
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },
  },

  mounted(){
    this.loadClients();
  },

}
</script>
