<template>
  <v-expand-transition appear>
    <v-card class="d-flex" :elevation="1" min-height="50px">
      <div class="question-drag">
        <div class="px-1 d-flex align-center" style="cursor: move; height: 50px">
          <v-icon>mdi-drag</v-icon>
        </div>
      </div>
      <div class="flex-grow-1">
        <div
          class="py-2 pl-2 pr-4 d-flex align-center text-caption"
          :class="{
            'error--text text--darken-1': haveError,
          }"
          v-ripple @click="$emit(isExpanded ? 'collapse':'expand')"
          style="cursor: pointer; height: 50px"
        >
          <span class="font-weight-medium text-body-1">
            {{
              !question.title ? "Pergunta Vazia"
              : (
                $utils.capitalize(question.title)
                + (question.required ? " *":"")
              )
            }}
          </span>
          <v-divider vertical class="mx-2" />
          {{ answerTypeText }}
          <template v-if="haveDisplayCondition">
            <v-divider vertical class="mx-2" />
            {{ currentDisplayConditionText }}
          </template>
        </div>
        <v-expand-transition>
          <div v-show="isExpanded" class="pb-4 pr-4">

            <div class="d-flex align-center pb-1">
              <v-menu v-model="conditionMenu" :close-on-content-click="false" width="200px">
                <template #activator="{ on }">
                  <v-chip color="grey lighten-3" label class="rounded-r-0">
                    Aparece
                  </v-chip>
                  <v-chip
                    v-on="on"
                    :disabled="disabled"
                    :color="haveConditionError ? 'warning darken-1 font-weight-medium':undefined"
                    label class="rounded-l-0 pl-3 pr-4"
                  >
                    {{ currentDisplayConditionText }}
                    <v-icon right>mdi-pencil</v-icon>
                  </v-chip>
                </template>
                <v-card class="pa-3">
                  <v-select
                    hide-details
                    v-model="question.question_order_display_condition"
                    :items="availableQuestions"
                    item-text="title"
                    item-value="question_order"
                    no-data-text="Coloque antes uma pergunta de seleção"
                    dense filled
                    label="Se a resposta de"
                    :disabled="disabled"
                    clearable
                  />
                  <v-select
                    label="For selecionada"
                    v-model="question.answer_order_display_condition"
                    dense
                    filled
                    hide-details
                    :items="selectedConditionQuestionOptions"
                    item-value="index"
                    item-text="text"
                    no-data-text="Selecione a pergunta primeiro"
                    :disabled="disabled"
                    clearable
                  />
                  <div class="d-flex justify-end">
                    <v-btn
                      color="grey darken-1 white--text"
                      class="mt-2"
                      depressed
                      @click="conditionMenu = false"
                    >
                      ok
                    </v-btn>
                  </div>
                </v-card>

              </v-menu>
              
              <v-spacer />

              <div>
                <v-btn
                  small depressed
                  class="error"
                  height="32px"
                  @click.stop="$emit('delete')"
                  :disabled="disabled"
                >
                  <v-icon small left>mdi-delete</v-icon>
                  Deletar
                </v-btn>
              </div>
            </div>

            <!--- FORM --->
            <v-text-field
              ref="questionTitleField"
              v-model="question.title"
              hide-details="auto"
              :rules="[$rules.required]"
              filled
              label="Pergunta *"
              :disabled="disabled"
            />
            <v-textarea
              v-model="question.description"
              hide-details="auto"
              filled
              label="Descrição"
              :rows="2"
              auto-grow
              :disabled="disabled"
            />
            <v-select
              filled
              hide-details
              :items="answerTypes"
              v-model="question.answer_type"
              item-text="label"
              label="Tipo de Resposta"
              :disabled="disabled"
            />
            <!-- OPTIONS -->
            <v-expand-transition>
              <v-card class="mt-2 pa-2" v-show="['radio','checkbox'].includes(question.answer_type)">
                <div class="text-subtitle-1 font-weight-medium">
                  Configuração do Campo de Seleção
                </div>
                <v-row v-show="question.answer_type == 'checkbox'" class="mt-2" no-gutters>
                  <v-col :cols="6">
                    <v-text-field
                      filled dense hide-details
                      v-model.number="question.required_min"
                      @keypress="$utils.keyPressOnlyNumbers"
                      type="number"
                      class="mr-1"
                      label="Minimo de Selecionados"
                      :disabled="disabled"
                    />
                  </v-col>
                  <v-col :cols="6">
                    <v-text-field
                      filled dense hide-details
                      v-model.number="question.required_max"
                      @keypress="$utils.keyPressOnlyNumbers"
                      class="ml-1"
                      type="number"
                      label="Máximo de Selecionados"
                      :disabled="disabled"
                    />
                  </v-col>
                </v-row>
                <div class="text-body-2 mt-2">
                  Opções:
                </div>
                <v-scale-transition group tag="div" class="d-flex flex-wrap ml-n2 pb-1">
                  <div
                    v-for="(option, i) in question.options" :key="i"
                    style="width: 50%"
                    class="
                      pt-2 pl-2
                      d-flex align-start
                    "
                  >
                    <v-text-field
                      class="flex-grow-1 rounded-r-0"
                      v-model="question.options[i]"
                      @keyup="optionChanged(i)"
                      hide-details="auto"
                      :rules="i == question.options.length-1 ? []:[$rules.required]"
                      filled dense
                      :placeholder="
                        i != question.options.length-1
                          ? 'Opção ' + (i + 1)
                          : 'Nova Opção'
                      "
                      :disabled="disabled"
                    />
                    <v-btn
                      v-if="i < question.options.length-1"
                      class="pa-1 rounded-l-0"
                      color="error"
                      depressed
                      width="auto"
                      min-width="0"
                      height="40px"
                      @click="question.options.splice(i, 1)"
                      :disabled="disabled"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-scale-transition>
              </v-card>
            </v-expand-transition>
            <v-expand-transition>
              <template v-if="question.answer_type == 'file'">
                <v-card class="mt-2 pa-2">
                  <div class="text-subtitle-1 font-weight-medium mb-2">
                    Configuração do Arquivo
                  </div>
                  <v-row class="mt-2" no-gutters>
                    <v-col :cols="6">
                      <v-text-field
                        filled dense hide-details
                        v-model.number="question.required_min"
                      @keypress="$utils.keyPressOnlyNumbers"
                        type="number"
                        class="mr-1"
                        label="Minimo de Arquivos (opcional)"
                      />
                    </v-col>
                    <v-col :cols="6">
                      <v-text-field
                        filled dense hide-details
                        v-model.number="question.required_max"
                        @keypress="$utils.keyPressOnlyNumbers"
                        type="number"
                        label="Máximo de Arquivos (opcional)"
                      />
                    </v-col>
                  </v-row>
                  <MultipleSelect
                    label="Restrições no tipo do arquivo (opcional)"
                    :items="allowed_types"
                    item-text="name"
                    no-data-text="Nenhum tipo encontrado"
                    filled
                    return-object
                    hide-details
                    multiple
                    :disabled="disabled"
                    v-model="question.allowed_types"
                  />
                  <DocCategorySelect
                    v-model="question.categories"
                    label="Categorias do Documento *"
                    multiple
                    :disabled="disabled"
                    :rules="[$rules.requiredList]"
                  />
                </v-card>
              </template>
            </v-expand-transition>
            <v-expand-transition appear>
              <v-checkbox
                v-show="!['checkbox','file'].includes(question.answer_type)"
                v-model="question.required"
                label="Resposta Obrigatória"
                hide-details="auto"
                class="my-2 ml-0"
                :disabled="disabled"
              />
            </v-expand-transition>
          </div> <!-- /isExpanded -->
        </v-expand-transition>
      </div>
      <div>
        <v-btn large icon @click="$emit(isExpanded ? 'collapse':'expand')">
          <v-icon>
            {{ isExpanded ? 'mdi-chevron-up':'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script>
import DocCategorySelect from "@/components/documents/DocCategorySelect.vue";
import MultipleSelect from '@/components/core/MultipleSelect.vue';

const emptyQuestion = JSON.stringify({
  title: "",
  description: "",
  required: true,
  required_min: 1,
  required_max: undefined,
  answer_type: 'text',
  question_order_display_condition: undefined,
  answer_order_display_condition: undefined,
  categories: [],
  allowed_types: [],
  options: [""],
});

export default {
  name: "FormQuestion",

  props: {
    value: {required: true, type: Object},
    isExpanded: {required: true, type: Boolean},
    availableQuestions: {required: true, type: Array},
    disabled: {required: true, type: Boolean},
    allowed_types: {required: true, type: Array},
  },

  components: {
    DocCategorySelect,
    MultipleSelect,
  },

  data(){
    let value = this.value;
    if (!value.title){
      value = JSON.parse(emptyQuestion);
      value.question_order = this.value.question_order;
      this.$emit('input', value);
    }
    return {
      question: value,
      answerTypes: [
        { label: "Arquivo", value: "file" },
        { label: "Data", value: "date" },
        { label: "Texto", value: "text" },
        { label: "CPF", value: "cpf" },
        { label: "CNPJ", value: "cnpj" },
        { label: "Valor Monetário", value: "monetary" },
        { label: "Seleção Única", value: "radio" },
        { label: "Seleção Multipla", value: "checkbox" },
        { label: "Telefone", value: "phone" },
        { label: "Texto Grande", value: "largetext" },
      ],
      conditionMenu: false,
    };
  },
  
  computed: {
    haveError(){
      return (
        !this.question.title || this.haveConditionError || this.haveOptionsError
      );
    },
    haveConditionError(){
      return this.haveDisplayCondition && this.question.answer_order_display_condition == undefined;
    },
    haveOptionsError(){
      return ['checkbox', 'radio'].includes(this.question.answer_type)
              && this.question.options.length <= 2;
    },
    answerTypeText(){
      return (this.answerTypes.filter(q => this.question.answer_type == q.value)[0] || {}).label;
    },
    haveDisplayCondition(){
      return this.question.question_order_display_condition != undefined;
    },
    selectedConditionQuestion(){
      if (this.haveDisplayCondition){
        const question = this.availableQuestions.filter((q) =>
          q.question_order == this.question.question_order_display_condition
        )[0];
        if (question == undefined){
          this.question.question_order_display_condition = undefined;
          this.question.answer_order_display_condition = undefined;
          return undefined;
        }
        return question;
      }
    },
    selectedConditionQuestionOptions(){
      if (this.selectedConditionQuestion != undefined){
        return this.selectedConditionQuestion.options.slice(0, -1).map((option, i) => ({
          index: i+1,
          text: option,
        }));
      }
      return [];
    },
    currentDisplayConditionText(){
      if (this.selectedConditionQuestion == undefined){
        return "Sempre";
      }
      let option = "?";
      let selected = this.question.answer_order_display_condition;
      selected = this.selectedConditionQuestionOptions.filter(option => option.index == selected)[0]
      if (selected != undefined){
        option = selected.text;
      }
      return `Se "${(this.selectedConditionQuestion || {}).title}" for "${option}"`;
    },
  },

  methods: {
    optionChanged(i){
      if (this.question.options[i] && i == this.question.options.length-1){
        this.question.options.push("");
      }
    },
  },

  watch: {
    value(value){
      this.question = value || {};
    },
    question: {
      handler(question) {
        this.$emit('input', question);
      },
      deep: true,
    },
    'question.title'(){
      this.$emit('options-changed');
    },
    'question.options'(){
      this.$emit('options-changed');
    },
    'question.answer_type'(current, previous){
      const wasAvailable = ['checkbox','radio'].includes(previous);
      const isAvailable = ['checkbox','radio'].includes(current);
      if (wasAvailable != isAvailable){
        this.$emit('availability-changed', isAvailable);
      }
    },
  },
}
</script>
