<template>
  <v-dialog v-model="dialog" width="1200px" persistent>
    <v-card tile :max-height="dialogHeight" :loading="loading && !initialTitle">
      <v-toolbar flat dark color="primary" max-height="65px">
        <v-toolbar-title>
          {{ initialTitle || "Formulário em branco" }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <div class="d-flex pl-2 py-2">
        <v-form
          ref="formDataForm"
          :style="{
            width: '400px',
            'max-height': formHeight + 'px',
            overflow: 'auto',
          }"
          @submit.prevent="saveFormRequest()"
        >
          <v-text-field
            class="mb-1"
            v-model="form_request.title"
            label="Título *"
            hide-details="auto"
            filled
            :rules="[$rules.required]"
            :disabled="loading"
          />
          <v-textarea
            class="mb-1"
            v-model="form_request.description"
            label="Descrição"
            hide-details="auto"
            filled
            auto-grow
            :rows="2"
            :disabled="loading"
          />
          <template v-if="formType != 'template'">
            <CompanyField
              v-if="!editing"
              label="Clientes *"
              v-model="form_request.clients"
              multiple
              :disabled="loading"
              :rules="[$rules.required,$rules.requiredList]"
              class="mb-1"
            />
            <CompanyField
              v-else
              label="Cliente *"
              v-model="form_request.client_id"
              :disabled="loading"
              :rules="[$rules.required]"
              class="mb-1"
            />
          </template>
          <DocCategorySelect
            label="Categorias do Formulário *"
            v-model="form_request.categories"
            :disabled="loading"
            multiple
            :rules="[$rules.requiredList]"
            class="mb-1"
          />
          <DateField
            v-if="formType == 'requested'"
            label="Data de Expiração"
            v-model="form_request.expiration_date"
            clearable
          />
          <template v-if="formType == 'recurrent'">
            <v-text-field
              label="Dia da Recorrência *"
              filled
              class="mb-1"
              v-model.number="form_recurrence.schedule_day"
              @keypress="$utils.keyPressOnlyNumbers"
              hide-details="auto"
              :rules="[$rules.required]"
              :disabled="loading"
            />
          </template>
        </v-form>
        <v-form
          ref="questionsForm"
          class="flex-grow-1"
          :style="{
            width: '400px',
            'max-height': formHeight + 'px',
            overflow: 'auto',
          }"
          @submit.prevent="saveFormRequest()"
        >
          <draggable
            v-model="questions"
            :animation="150"
            handle=".question-drag"
            @change="computeAvailableQuestions()"
          >
            <template v-for="(question, i) in questions">
              <FormQuestion
                :key="question.question_order"
                class="mb-1 mx-2"
                :disabled="loading"
                :allowed_types="allowed_types"
                :isExpanded="expandedQuestion == question.question_order"
                @expand="expandedQuestion = question.question_order"
                @collapse="expandedQuestion = undefined"  
                :availableQuestions="availableQuestions[i]"
                v-model="questions[i]"
                @delete="removeQuestion(i)"
                @options-changed="updateAvailableQuestionOptions(i)"
                @availability-changed="toggleAvailableQuestion(i, $event)"
              />
            </template>
          </draggable>
          <div class="px-2 pt-1">
            <v-btn
              class="primary"
              large block :elevation="1"
              @click="pushEmptyQuestion()"
              :disabled="loading"
            >
              <v-icon class="mr-1">mdi-plus</v-icon>
              Nova Pergunta
            </v-btn>
          </div>
        </v-form>
      </div>
      <v-card-actions class="pa-2">
        <v-spacer />
        <v-btn text @click="dialog = false" :disabled="loading">
          Cancelar
        </v-btn>
        <v-btn
          class="px-2"
          :loading="loading"
          color="success"
          @click="saveFormRequest()"
        >
          {{
            (formType != 'template' && !editing) ? "Criar" : "Salvar"
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <FormLink ref="formLink" />
  </v-dialog>
</template>

<script>
import DateField from "@/components/core/DateField.vue";
import DocCategorySelect from "@/components/documents/DocCategorySelect.vue";
import FormLink from "@/components/form_requests/FormLink.vue";
import CompanyField from '@/components/CompanyField.vue';
import FormQuestion from "@/components/form_requests/FormQuestion.vue";
import draggable from 'vuedraggable';

export default {
  name: "FormCreatorDialog",

  components: {
    DateField,
    DocCategorySelect,
    FormLink,
    CompanyField,
    FormQuestion,
    draggable,
  },
  
  data: () => ({
    initialTitle: undefined,
    loading: false,
    dialog: false,
    categories: [],
    expandedQuestion: undefined,
    questions_count: 1,
    allowed_types: [],
    form_request: {
      clients: [],
      categories: [],
    },
    form_recurrence: {},
    questions: [],
    availableQuestions: [],
    formType: '',
    editing: false,
    from_template: false,
  }),

  computed: {
    dialogHeight(){
      return this.$vuetify.breakpoint.height * 0.9;
    },
    formHeight(){
      return this.dialogHeight - 52 - 64 - 16;
    },
  },

  methods: {
    updateAvailableQuestionOptions(index){
      const question = this.questions[index];
      for (let i = index+1; i < this.questions.length; ++i){
        for (let j = 0; j < this.availableQuestions[i].length; ++j){
          if (this.availableQuestions[i][j].question_order == question.question_order){
            this.availableQuestions[i][j].options = question.options;
            this.availableQuestions[i][j].title = question.title;
            break;
          }
        }
      }
    },
    toggleAvailableQuestion(index, isAvailable){
      if (isAvailable){
        const question = {
          ...this.questions[index],
          index: index,
        };
        for (let i = index+1; i < this.questions.length; ++i){
          let added = false;
          for (let j = 0; j < this.availableQuestions[i].length; ++j){
            if (this.availableQuestions[i][j].index > index){
              this.availableQuestions[i].splice(j, 0, question);
              added = true;
              break;
            }
          }
          if (!added){
            this.availableQuestions[i].push(question);
          }
        }
      } else {
        for (let i = index+1; i < this.questions.length; ++i){
          for (let j = 0; j < this.availableQuestions[i].length; ++j){
            if (this.availableQuestions[i][j].question_order == this.questions[index].question_order){
              this.availableQuestions[i].splice(j, 1);
              break;
            }
          }
        }
      }
    },
    computeAvailableQuestions() {
      let available = [];
      for (let i = 0; i < this.questions.length; ++i){
        available.push(
          this.questions
            .slice(0, i)
            .map((q, j) => ({
              index: j,
              ...q,
            }))
            .filter(q => ['checkbox','radio'].includes(q.answer_type))
        );
      }
      this.availableQuestions = available;
    },
    open(type, id, from_template) {
      this.formType = type;
      this.expandedQuestion = undefined;
      this.from_template = !!from_template;
      this.initialTitle = '';
      if (id) {
        this.editing = true;
        this.loadFormById(id);
      } else {
        this.editing = false;
        switch (this.formType){
          case 'requested':
            this.initialTitle = "Formulário em Branco";
            break;
          case 'template':
            this.initialTitle = "Novo Formulário Modelo";
            break;
          case 'recurrent':
            this.initialTitle = "Novo Formulário Recorrente";
            break;
        }
        this.form_request = {
          title: "",
          description: "",
          clients: [],
          expiration_date: null,
          categories: [],
        };
        this.expandedQuestion = 0;
        this.questions_count = 1;
        this.questions = [];
        this.pushEmptyQuestion()
      }
      if (this.$refs.formDataForm){
        this.$refs.formDataForm.resetValidation();
      }
      if (this.$refs.questionsForm){
        this.$refs.questionsForm.resetValidation();
      }
      this.$nextTick(() => {
        this.dialog = true;
        this.loadFileTypes();
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
      this.expandedQuestion = undefined;
    },
    pushEmptyQuestion() {
      let order = this.questions_count++;
      this.questions.push({
        question_order: order,
      });
      this.expandedQuestion = order;
      this.availableQuestions.push(
        this.questions
          .slice(0, this.questions.length-1)
          .map((q, j) => ({
            index: j,
            ...q,
          }))
          .filter(q => ['checkbox','radio'].includes(q.answer_type))
      );
    },
    saveFormRequest() {
      if (!this.$refs.formDataForm.validate() || !this.$refs.questionsForm.validate()) {
        this.$showMessage('warning', "Preencha os campos corretamente");
        return;
      }
      for (let i = 0; i < this.questions.length; ++i){
        let question = this.questions[i];
        if (['checkbox','radio'].includes(question.answer_type)){
          if (question.options.length-1 < 2){
            this.$showMessage('warning', "As perguntas de seleção devem ter ao menos 2 opções");
            return;
          }
        }
        if (['checkbox','file'].includes(question.answer_type)){
          if (question.required_max && question.required_max <= 0){
            this.$showMessage('warning', "O máximo deve ser maior que zero");
            return;
          }
          if (question.required_min && question.required_min < 0){
            this.$showMessage('warning', "O mínimo deve ser positivo");
            return;
          }
          if (
            question.required_min && question.required_max
            && question.required_max < question.required_min
          ){
            this.$showMessage('warning', "O máximo deve ser maior ou igual ao mínimo");
            return;
          }
        }
        if (question.answer_type == 'checkbox'){
          if (question.required_min > question.options.length-1){
            this.$showMessage('warning', "O mínimo deve ser menor ou igual ao número de opções");
            return;
          }
        }
      }
      this.loading = true;
      const data = {
        ...this.form_request,
        ...(this.formType == 'recurrent' ? this.form_recurrence:{}),
        questions: this.questions.map((question, i) => ({
          ...question,
          required_min: question.required_min || undefined,
          required_max: question.required_max || undefined,
          categories: question.answer_type == 'file' ? question.categories : undefined,
          options: question.options.slice(0, -1).map((txt, j) => ({
            option_order: j+1,
            value: txt,
          })),
        })),
      };
      let request = '/form_request';
      if (this.formType == 'recurrent'){
        request += '/recurrent';
      }
      if (this.editing){
        request = this.$axios.put(`${request}/${this.form_request.id}`, data);
      } else {
        request = this.$axios.post(request, data);
      }
      request
        .then((response) => {
          this.dialog = false;
          if (this.editing){
            this.$showMessage("success", "Formulário Salvo");
          } else {
            this.$showMessage("success", "Formulário Criado");
            if (this.formType == 'requested' && !this.editing){
              const created = response.data;
              if (created.length == 1){
                this.$emit('created', created[0].id);
              } else {
                this.$refs.formLink.open(response.data);
              }
            }
          }
          this.$emit('updated');
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Erro ao enviar o formulário");
        })
        .finally(() => {
          this.loading = false;
          this.$emit("reset");
        });
    },
    async loadFormById(id) {
      this.loading = true;
      let request = '/form_request';
      if (this.formType == 'recurrent' && !this.from_template){
        request += '/recurrent';
      }
      try {
        const response = await this.$axios.get(`${request}/${id}`);
        const data = response.data;
        this.form_request = {
          ...data,
          categories: data.categories.map(c => c.id),
          client_id: (data.client || {}).id,
        };
        this.form_recurrence = {
          schedule_day: data.schedule_day,
        };
        this.questions = data.questions.map((question, i) => ({
          ...question,
          categories: question.categories.map(c => c.id),
          options: [...question.options.map(o => o.value), ""],
        }));
        this.questions_count = this.questions.length+1;
        this.computeAvailableQuestions();
        if (this.formType == 'template'){
          this.initialTitle = "Editando Modelo " + data.title;
        } else {
          const typeTitle = (this.formType == 'recurrent') ? "Recorrência":"Formulário";
          if (!data.client_id){
            this.editing = false;
            this.initialTitle = `Usando modelo ${data.title} para ${typeTitle}`;
          } else {
            this.editing = true;
            this.initialTitle = `Editando ${typeTitle} ${data.title}`;
          }
        }
      } catch (error){
        console.log(error);
        this.$showMessage("error", "Não foi possivel carregar o formulário");
      } finally {
        this.loading = false;
        this.dialog = true;
      }
    },
    loadFileTypes() {
      this.$axios
        .get('/file-types')
        .then((response) => {
          this.allowed_types = response.data;
        })
        .catch((error) => {
          this.$showMessage(
            'error',
            "Não foi possivel carregar os Tipos de arquivo"
          );
          this.dialog = false;
        });
    },
  },

  watch: {
    dialog(isShowing, wasShowing){
      if (!isShowing && wasShowing){
        setTimeout(() => {
          this.initialTitle = "";
          this.form_request = {
            title: "",
            description: "",
            clients: [],
            expiration_date: null,
            categories: [],
          };
          this.questions = [];
          this.availableQuestions = [];
        }, 200);
      }
    },
  },

};
</script>
