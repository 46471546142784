<template>
  <v-dialog v-model="uploadDialog" persistent width="1100px">
    <v-card>
      <v-toolbar flat dark color="primary" height="60px">
        <v-toolbar-title>
          Upload de Documentos
        </v-toolbar-title>
        <v-toolbar-items class="flex-grow-1 d-flex align-center">
          <v-spacer />
          <v-btn icon @click="uploadDialog = false" :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form" class="flex-grow-1 pa-2 d-flex flex-column">

        <div ref="beforeFiles" v-resize="setBeforeFilesMaxHeight">
          <!------ FIELDS ------>
          <DocCategorySelect
            v-model="categories"
            :disabled="loading"
            multiple
            :rules="[$rules.requiredList]"
          />
          <CompanyField
            v-model="selectedClients"
            multiple
            label="Destino *"
            :rules="[$rules.requiredList]"
            allowWithoutClient
          />

          <!------ FILES DROP ------>
          <v-card
            ref="filesDrop"
            :disabled="loading"
            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
            @drop.prevent="onFile($event)"
            @click="$refs.file.click()"
            class="d-flex align-center justify-center grey--text mt-2"
            outlined
            height="100px"
            width="100%"
          >
            <h3>Clique aqui ou arraste para fazer Upload dos arquivos.</h3>
            <input
              type="file"
              ref="file"
              multiple
              @change="onFile($event)"
              style="display: none"
            />
          </v-card>
        </div>

        <!------ FILES ------>
        <div class="flex-grow-1 mt-2" :style="{
          'max-height': maxContentHeight + 'px',
          overflow: 'auto',
        }">
          <v-scale-transition group>
            <v-card
              v-for="(doc, i) in documents"
              :key="i"
              tile class="pa-2 my-1 d-flex align-center"
              elevation="0"
              :color="situationColor(doc)"
            >
              <v-text-field
                label="Arquivo"
                dense filled color="primary" class="mr-1"
                hide-details="auto"
                style="width: 150px;"
                height="56px"
                :disabled="loading"
                v-model="doc.file.name"
                readonly
              />
              <v-text-field
                label="Nome *"
                dense filled color="primary" class="mr-1"
                hide-details="auto"
                style="width: 150px"
                height="56px"
                v-model="doc.name"
                :rules="[$rules.required]"
                :disabled="loading"
              />
              <v-textarea
                class="flex-grow-1 pt-0"
                hide-details="auto"
                v-model="doc.description"
                :disabled="loading"
                filled auto-grow dense
                :row-height="14"
                :rows="1"
                label="Descrição"
              />
              <v-btn
                class="ml-2" icon
                :disabled="loading" @click="removeDocument(i)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-scale-transition>
        </div>
        <div class="d-flex mt-2 align-center justify-end">
          <div v-if="loading" class="flex-grow-1">
            <v-progress-linear
              color="primary"
              height="10"
              :value="progress"
            />
          </div>
          <div class="mx-4">
            {{ loading ? "Enviando":"" }}
            {{ documents.length }} arquivos
          </div>
          <v-btn
            large
            :loading="loading"
            @click="sendFiles()"
            class="success mb-auto"
          >
            Enviar
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DocCategorySelect from '@/components/documents/DocCategorySelect.vue';
import CompanyField from "@/components/CompanyField.vue";

export default {
  name: "SendDocument",
  
  components: {
    DocCategorySelect,
    CompanyField,
  },

  data: () => ({
    uploadDialog: false,
    loading: false,
    loadingClients: true,

    categories: [],
    selectedClients: [],
    documents: [],

    clients: [],
    progress: 0,
    resizeObserver: undefined,
    beforeFilesHeight: 0,
  }),

  computed: {
    maxContentHeight(){
      return this.$vuetify.breakpoint.height*0.9 /*max dialog height*/
        - 60 /*toolbar*/
        - 44 /*action button*/
        - 8*4 /*padding*/
        - this.beforeFilesHeight;
    },
  },

  methods: {
    initializeResizeObserver(){
      if (this.resizeObserver == undefined){
        this.$nextTick(() => {
          this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries){
              if (
                entry.target.clientHeight != undefined
                && entry.target.clientHeight != this.beforeFilesHeight
              ){
                this.setBeforeFilesMaxHeight();
                return;
              }
            }
          });
          this.resizeObserver.observe(this.$refs.beforeFiles);
        });
      }
    },
    setBeforeFilesMaxHeight(){
      if (this.$refs.beforeFiles != undefined){
        this.$nextTick(() => {
          this.beforeFilesHeight = this.$refs.beforeFiles.clientHeight;
          this.initializeResizeObserver();
        });
      }
    },
    onFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      files.forEach(file => {
        this.documents.push({
          file: file,
          name: this.removeExtension(file.name),
          description: "",
        });
      });
    },
    removeDocument(index) {
      this.documents.splice(index, 1);
    },
    open() {
      this.documents = [];
      this.selectedClients = [];
      this.uploadDialog = true;
      if (this.$refs.form){
        this.$refs.form.reset();
      }
    },
    situationColor(document) {
      if (document.success) return "green lighten-2";
      else if (document.error) return "red lighten-4";
      else return "grey lighten-4";
    },
    removeExtension(fileName) {
      let index = fileName.lastIndexOf('.');
      return fileName.substring(0, index);
    },
    async sendFiles() {
      if (!this.$refs.form.validate()){
        this.$showMessage('warning', "Preencha os campos corretamente");
        return;
      }
      this.progress = 0;
      let numFailures = 0;
      this.loading = true;
      for (let i = 0; i < this.documents.length; i++) {
        try {
          this.documents[i].loading = true;
          await this.sendFile(this.documents[i]);
          this.documents[i].success = true;
          this.progress = (100.0 / this.documents.length) * (i+1);
        } catch (e){
          numFailures += 1;
        } finally {
          this.documents[i].loading = false;
        }
      }
      this.loading = false;
      if (numFailures > 0){
        this.$showMessage('error', "Erro inesperado ao enviar alguns arquivos. Os arquivos enviados foram removidos da lista.");
        this.documents = this.documents.filter((doc) => {
          return !doc.success;
        });
      } else {
        this.uploadDialog = false;
        this.$showMessage('success', "Documentos enviados");
      }
      this.$emit("updated");
    },
    async sendFile(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("name", file.name);
      if (file.description){
        formData.append("description", file.description);
      }
      formData.append("clients", this.selectedClients.join(','));
      formData.append("categories", this.categories.join(','));
      const baseProgress = this.progress;
      return this.$axios.post("/document", formData, {
        onUploadProgress: (event) => {
          let fileProgress = event.loaded / event.total;
          this.progress = baseProgress + (100.0 / this.documents.length) * fileProgress;
        },
      });
    },
  },
};
</script>
