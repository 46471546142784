<template>
  <div>
    <div class="pa-4 d-flex align-center">
      <h2>
        Documentos
      </h2>
      <v-spacer />
      <v-menu
        v-model="filtersMenu"
        offset-y
        min-width="500px"
        max-width="500px"
        :close-on-content-click="false">
        <template #activator="{ on }">
          <v-badge
            :value="numActiveFilters > 0"
            :content="numActiveFilters"
            color="warning black--text"
            overlap left bordered
          >
            <v-btn
              v-on="on"
              color="primary"
              class="mr-4"
            >
              Filtrar
            </v-btn>
          </v-badge>
        </template>

        <!------------- FILTERS ------------->
        <v-card class="pa-3">
          <!-- text filter -->
          <v-text-field
            filled
            hide-details="auto"
            v-model="searchQuery"
            label="Nome"
            prepend-inner-icon="mdi-magnify"
            clearable
          />
            
          <!-- client -->
          <v-autocomplete
            filled
            hide-details="auto"
            v-model="selectedClient"
            clearable
            :items="clients"
            :loading="loadingClients"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-account-filter"
            label="Destino"
          />
          <!-- date interval picker -->
          <v-menu
            class="mb-auto"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="fade-transition"
            offset-overflow
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                filled
                hide-details="auto"
                :value="dateRangeText"
                label="Selecionar Intervalo de Datas"
                prepend-inner-icon="mdi-calendar-multiselect"
                readonly
                clearable
                v-on="on"
                @click:clear="selectedDates = []"
              />
            </template>
            <v-card>
              <v-date-picker
                v-model="selectedDates"
                type="month"
                range
                no-title
                color="primary"
                header-color="primary"
                :show-current="false"
                locale="pt-br"
              >
                <v-spacer />
                <v-btn
                  small text color="primary" class="mt-n4"
                  @click="datePickerMenu = false"
                >
                  fechar
                </v-btn>
              </v-date-picker>
            </v-card>
          </v-menu> 
          <!-- categories -->
          <DocCategorySelect
            v-model="selectedCategories"
            multiple
          />
          <v-checkbox
            v-model="includeArchived"
            label="Incluir Arquivados"
            hide-details
            class="mt-2"
          />
        </v-card>
        <!------------- /FILTERS ------------->
        
      </v-menu>
      <v-btn
        v-if="$store.state.user.is_accountant"
        @click="$refs.sendDocumentDialog.open()"
        color="secondary"
      >
        Upload
      </v-btn>
    </div>

    <v-divider />
    
    <v-data-table
      :height="contentHeight-69"
      :headers="headers"
      :items="documents"
      :loading="loading" loader-height="1"
      @click:row="openViewDocument"
      disable-pagination hide-default-footer
      fixed-header
      loading-text="Carregando... Por favor, aguarde"
      must-sort
      :custom-sort="updateSort"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      style="cursor: pointer"
    >
      <template #no-data>
        <div v-if="tryAgain" class="py-5 d-flex flex-column align-center black--text">
          Erro ao carregar documentos
          <br/>
          <v-btn small depressed color="error" class="mt-4" @click="tryAgain = false">
            tentar novamente
          </v-btn>
        </div>
        <template v-else>
          Nenhum documento encontrado
        </template>
      </template>

      <template #[`item.client`]="{ item }" >
        {{item.client ? item.client.name:"Arquivo do Escritório"}}
      </template>

      <template v-slot:[`item.categories`]="{ item }">
        <DocCategoryChips :categories="item.categories" />
      </template>

      <template v-slot:[`item.upload_date`]="{ item }">
        {{ $utils.formatDateTime(item.upload_date) }}
      </template>

      <template #[`item.form_request`]="{ item }" >
        <v-btn
          v-if="item.form_request_id"
          class="ro unded pa-2"
          depressed
          color="success lighten-3 success--text text--darken-4"
          min-width="0"
          width="auto"
          height="auto"
          @click.stop="$refs.formDialog.open(item.form_request_id)"
        >
          <v-icon>mdi-form-select</v-icon>
        </v-btn>
      </template>

      <template #[`body.append`]>
        <tr v-if="!fullyLoaded && !tryAgain && started">
          <td :colspan="headers.length">
            <scroll-loader
              :loader-method="loadDocuments"
              :loader-disable="fullyLoaded"
            >
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
              />
            </scroll-loader>
          </td>
        </tr>
      </template>

    </v-data-table>
    <SendDocument ref="sendDocumentDialog" @updated="resetLoader()" />
    <ViewDocument ref="viewDocument" @updated="resetLoader()"/>
    <RequestDialog ref="formDialog" />
  </div>
</template>

<script>
import SendDocument from "@/components/documents/SendDocument.vue";
import ViewDocument from "@/components/documents/ViewDocument.vue";
import RequestDialog from '@/components/form_requests/RequestDialog.vue';
import DocCategoryChips from '@/components/documents/DocCategoryChips.vue';
import DocCategorySelect from "@/components/documents/DocCategorySelect.vue";

export default {
  name: "Documents",
  components: {
    SendDocument,
    ViewDocument,
    DocCategoryChips,
    DocCategorySelect,
    RequestDialog,
  },

  props: ["contentHeight"],

  data: () => ({
    started: false,
    fullyLoaded: false,

    datePickerMenu: false,
    filtersMenu: false,
    searchQuery: "",
    selectedClient: undefined,
    selectedCategories: [],
    selectedDates: [],
    includeArchived: false,

    changeTimeout: undefined,
    sortBy: 'upload_date',
    sortDesc: true,

    loading: false,
    loadingClients: false,
    clients: [],
    tryAgain: false,
    limit: 30,
    headers: [
      { text: "Cliente", value: 'client' },
      { text: "Documento", value: 'name' },
      { text: "Categorias", value: 'categories' },
      { text: "Data de Envio", value: 'upload_date' },
      { text: "Formulário", value: 'form_request' },
    ],
    documents: [],
  }),

  computed: {
    dateRangeText() {
      var dates = this.selectedDates.map(this.formatPickedDateDate);
      if (dates.length == 0){
        return "";
      }
      if (dates.length == 1){
        return "A partir de " + dates[0];
      }
      this.datePickerMenu = false;
      return "De " + dates.join(" a ");
    },
    numActiveFilters(){
      let count = 0;
      if (this.searchQuery) ++count;
      if (this.selectedClient) ++count;
      if (this.includeArchived) ++count;
      count += this.selectedCategories.length;
      count += this.selectedDates.length;
      return count;
    },
  },

  methods: {
    openViewDocument(document) {
      this.$refs.viewDocument.open(document);
    },
    formatPickedDateDate(date) {
      const months = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
      let [year, month] = date.split("-")
      return ((months[parseInt(month)-1] + " " + year));
    },
    resetLoader(){
      if (this.changeTimeout != undefined){
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loading){
          this.resetLoader();
        } else {
          this.fullyLoaded = false;
          this.$set(this, 'documents', []);
        }
      }, 300);
    },
    withDay(yearmonth, lastday){
      if (!yearmonth){
        return undefined;
      }
      if (!lastday){
        return yearmonth + '-01';
      }
      const [year, month] = yearmonth.split('-');
      const day = new Date(year, month, 0).getDate();
      return yearmonth +'-'+ day;
    },
    updateSort(items, sortBy, sortDesc){
      sortBy = sortBy[0];
      sortDesc = sortDesc[0];
      if (this.sortBy != sortBy || this.sortDesc != sortDesc){
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.resetLoader();
      }
      return items;
    },
    reload(){
      if (this.started){
        this.resetLoader();
      } else {
        this.started = true;
      }
    },
    loadDocuments() {
      this.loading = true;
      this.tryAgain = false;
      this.$axios.patch(
        `/documents`,
        {
          text: this.searchQuery,
          archived: this.includeArchived,
          text: this.searchQuery,
          client_id: this.selectedClient,
          categories: this.selectedCategories,
          initial_date: this.withDay(this.selectedDates[0]),
          final_date: this.withDay(this.selectedDates[1], true),
          order_by_field: this.sortBy,
          order_by_desc: this.sortDesc,
        }, {
          params: {
            limit: this.limit,
            offset: this.documents.length,
          },
        })
        .then((response) => {
          this.documents = [
            ...this.documents,
            ...response.data
          ];
          if (response.data.length < this.limit){
            this.fullyLoaded = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.tryAgain = true;
          this.$showMessage("error", "Erro ao carregar os Documentos");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadClients() {
      this.loadingClients = true;
      this.$axios
        .get("/clients")
        .then((response) => {
          this.clients = [
            {
              id: -1,
              name: "Arquivo do Escritório",
            },
            ...response.data
          ];
        })
        .catch((error) => {
          this.$showMessage("error", "Erro inesperado ao carregar os clientes");
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },    
  },

  watch: {
    searchQuery(){ this.resetLoader(); },
    selectedClient(){ this.resetLoader(); },
    selectedCategories(){ this.resetLoader(); },
    selectedDates(){ this.resetLoader(); },
    includeArchived(){ this.resetLoader(); },
  },

  mounted(){
    this.loadClients();
  },

};
</script>
