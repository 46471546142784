<template>
  <v-input
    class="grey lighten-3 px-3 py-2"
    style="border-bottom: grey solid 1px !important;"
    hide-details="auto"
    :value="value"
    :rules="rules"
  >
    <div style="width: 100%" class="d-flex flex-column">
      <span class="white grey--text text--darken-2 px-1 text-caption grey lighten-3">
        {{ label || `Categoria${ multiple ? "s":"" }` }}
      </span>
      <div class="d-flex flex-wrap align-center mt-1 mx-n1">

        <template v-if="loading">
          <v-chip label class="ma-1">
            <v-progress-circular
              indeterminate
              :size="14"
              :width="2"
            />
          </v-chip>
        </template>

        <div v-else-if="error" class="ma-1 text-subtitle-2">
          Erro ao carregar
          <v-btn small color="error" class="ml-2" @click="loadCategories()">
            tentar novamente
          </v-btn>
        </div>

        <div v-else>
          <template v-for="category in categories">
            <v-chip
              :key="category.id"
              label class="ml-1 mt-1"
              :color="isSelected(category) ? category.color : 'grey lighten-2'"
              :class="
                isSelected(category) ?
                  `${$utils.textColorForBackground(category.color)}--text`
                  :''
              "
              :disabled="disabled"
              @click="categoryClicked(category)"
            >
              {{ category.name }}
            </v-chip>
          </template>
        </div>
        
      </div>
    </div>
    <template #append>
      <div style="width: 40px">
        <v-tooltip left :open-delay="600" v-if="$store.state.user.is_admin">
          <span>
            Gerenciar Categorias
          </span>
          <template #activator="{ on }">
            <v-btn
              fab depressed small fill-height color="primary darken-1 grey--text text--lighten-3"
              class="mb-1"
              :disabled="disabled"
              v-on="on" @click="$refs.categoriesDialog.open()"
            >
              <v-icon>mdi-notebook-edit</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip left :open-delay="600">
          <span>
            Recarregar Categorias
          </span>
          <template #activator="{ on }">
            <v-btn
              fab depressed small fill-height color="grey darken-1 grey--text text--lighten-3"
              :disabled="disabled || loading"
              v-on="on" @click="loadCategories()"
            >
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
    </template>
    <CategoriesDialog
      ref="categoriesDialog"
      @updated="loadCategories()"
    />
  </v-input>
</template>

<script>
import CategoriesDialog from "@/components/form_requests/CategoriesDialog.vue";

export default {
  name: "DocCategorySelect",

  components: { CategoriesDialog },

  props: {
    value: {required: true},
    label: {default: undefined, type: String},
    disabled: {required: false, type: Boolean, default: false},
    multiple: {required: false, type: Boolean, default: false},
    rules: {required: false, type: Array, default: undefined},
  },

  data(){
    return {
      loading: false,
      error: false,
      categories: [],
      selected: this.value || [],
    };
  },

  watch: {
    value(val){
      this.selected = val;
    },
    selected(val){
      this.$emit('input', val);
    },
  },

  methods: {
    isSelected(category){
      if (this.multiple){
        return this.selected.includes(category.id);
      } else {
        return this.selected == category.id;
      }
    },
    categoryClicked(category){
      if (this.multiple){
        if (!(this.selected instanceof Array)){
          this.selected = [this.selected];
        }
        if (this.selected.includes(category.id)){
          const index = this.selected.indexOf(category.id);
          this.selected.splice(index, 1);
        } else {
          this.selected.push(category.id);
        }
      } else {
        this.selected = category.id;
      }
    },
    loadCategories(){
      this.loading = true;
      this.error = false;
      this.$axios.get('/doc/categories')
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted(){
    this.loadCategories();
  },
}
</script>
