<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject"
    :items="items"
    ref="autocomplete"
    filled
    hide-details="auto"
    :chips="multiple"
    :deletable-chips="multiple"
    :clearable="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :rules="rules"
    :label="label"
    :no-data-text="noDataText"
    :no-results-text="noDataText"
  >
    <template #append v-if="multiple">
      <template v-if="($refs.autocomplete || {}).isMenuActive">
        <v-btn
          color="grey darken-1"
          depressed dark
          @click="$refs.autocomplete.blur()"
        >
          ok
        </v-btn>
      </template>
      <template v-else>
        <v-icon>mdi-menu-down</v-icon>
      </template>
    </template>

    <template #selection="{ parent, item, index, disabled }" v-if="multiple">
      <div class="mt-2 mr-1">
        <v-chip
          label
          color="grey lighten-1"
          class="ma-0 rounded-r-0"
          :disabled="disabled"
        >
          {{ item[parent.itemText] }}
        </v-chip>
        <v-chip
          label
          color="grey"
          class="ma-0 px-2 rounded-l-0"
          :disabled="disabled"
          @click="remove(index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-chip>
      </div>
    </template>

    <template #append-item v-if="!!newLabel">
      <v-list-item
        class="grey darken-1 font-weight-medium" dark
        @click="$emit('open-new')"
      >
        <v-list-item-action>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ newLabel }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "MultipleSelect",

  props: {
    value: {required: true},
    items:  {required: true, type: Array},
    'no-data-text': {required: true, type: String},
    'item-text': {required: true, type: String},
    'item-value': {default: undefined, type: String},
    'return-object': {default: false, type: Boolean},
    loading: {default: false, type: Boolean},
    multiple: {default: false, type: Boolean},
    disabled: {default: false, type: Boolean},
    clearable: {default: false, type: Boolean},
    rules: {default: undefined},
    label: {required: true, type: String},
    newLabel: {default: undefined, type: String},
  },

  methods: {
    remove(index){
      let list = [...this.value];
      list.splice(index, 1)
      this.$emit('input', list);
    },
  },
}
</script>
