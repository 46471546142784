<template>
  <v-fade-transition leave-absolute>

    <template v-if="editing">
      <slot
        name="field"
        v-if="!!$slots.field"
      />
      <v-textarea
        v-else-if="textarea"
        :label="label"
        :rows="2"
        auto-grow
        filled
        hide-details="auto"
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :disabled="disabled"
      />
      <v-text-field
        v-else
        :label="label"
        filled
        hide-details="auto"
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :disabled="disabled"
      />
    </template>

    <v-card
      v-else
      class="
        grey lighten-4
        px-3 py-2
        text-body-1 rounded-sm
      "
      :class="{
        'text-justify': textarea,
      }"
      :elevation="0"
      :width="width"
    >
      <div class="text-caption font-weight-light">
        {{ label }}
      </div>
      <slot v-if="$slots.default" />
      <template v-else>
        {{ value }}
      </template>
    </v-card>
  </v-fade-transition>
</template>

<script>
export default {
  name: "DataRow",

  props: {
    label: {required: true, type: String},
    width: {default: "auto"},
    value: {default: undefined},
    rules: {default: undefined, type: Array},
    disabled: {default: false, type: Boolean},
    editing: {default: false, type: Boolean},
    textarea: {default: false, type: Boolean},
  },

}
</script>
